/* eslint-disable object-shorthand */

export const PACKAGE_BUILD_DATE = '2025-02-28T10:23:10.437Z';
export const PACKAGE_NAME = 'invision-customercare';
export const PACKAGE_VERSION = '25.2.10000-1737834';

export default {
    PACKAGE_BUILD_DATE: PACKAGE_BUILD_DATE,
    PACKAGE_NAME: PACKAGE_NAME,
    PACKAGE_VERSION: PACKAGE_VERSION
};
